body {
  background: #efe;  
}

.App {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}
h1 {
  font-family: 'Niconne', cursive;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0e1236;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Niconne', cursive;
}

.App-link {
  color: #61dafb;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efe;
}

.card-container.current-card {
  z-index: 10;
}

.card-container.next-card {
  z-index: 5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flash-fade {
  from {
    opacity: .5;
  }
  to {
    opacity: 0;
  }
}

.pagination .left,
.pagination .right {
  background: #000;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20
}

.pagination .left {
  left: 0;  
  right: calc(100vw - 50px);
}
.pagination .right {
  right: 0;
  left: calc(100vw - 50px);
}

.pagination.flash .left,
.pagination.flash .right {
  animation-name: flash-fade;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;

}


/* animation of page turning */

@keyframes page-turn-next {
  from {
    left: 0;
    max-width: 600px;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    left: -50px;
    max-width: 0;
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}

@keyframes page-turn-prev {
  from {
    right: 0;
    max-width: 600px;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    right: -50px;
    max-width: 0;
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}

.card-container.current-card.prev .card {
  position: absolute;
  right: 0;
  /* bottom: 0; */
  animation-name: page-turn-prev;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.card-container.current-card.next .card {
  position: absolute;
  left: 0;
  /* bottom: 0; */
  animation-name: page-turn-next;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
/* .card-container.current-card.next * {
  min-width: 0!important;
} */


/* help overlay */
.help-overlay {
  background: #efe;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1em;
}

.pagination.permanent .right,
.pagination.permanent .left {
  z-index: 2000;
  opacity: .5;
}