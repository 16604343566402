.card {
    max-width: 600px;
    /* box-shadow: 4px 4px 2px #111; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em auto;
    border: 2px solid #5aba82;
    border-radius: 5px;
    background: #5aba82;
}
.card-contents {
    background: #135068;
    display: flex;
    flex-direction: column;
    border: 2px solid #efe;
    border-radius: 5px;
}
.blurb {
    background: #efe;
    display: flex;
    margin-bottom: 1em;
    padding: 1em;
    justify-content: center;
}
.group {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid #efe;
    margin-bottom: 1em;
    padding-bottom: 1em;
}
.group:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
}
.caption {
    background: #efe;
    max-width: 400px;
    display: flex;
    margin: 1em;
    border-radius: 5px;
    padding: 1em;
}

.photos {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    margin: 1em auto;
}
.videos > div {
    position: relative;
}
.play-button {
    z-index: 100;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
}
.photos img {
    border: 10px solid #eee;
    border-radius: 5px;
    min-width: 300px;
}
.videos video {
    border: 10px solid #eee;
    border-radius: 5px;
    width: 300px;
    max-width: 100vw;
}

.play-button img {
    width: 100px;
}